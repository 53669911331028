/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable tailwindcss/classnames-order */

import React, { useState, useEffect } from "react";
import {
  ASSESSMENT_DETAILS,
  INJURY_TYPES,
  keyConstants,
  failureMessageConstant
} from "../../constants";
import Utils from "../../utility";
import ClaimManagerService from "../../services/claimManagerService";
import { jwtDecode } from "jwt-decode";
import Cookies from "../../managers/sessionManager";
import { useNavigate } from "react-router";
const { ASSISMENT_DETAIL, ACCESS_TOKEN } = keyConstants;

const AssessmentReport = () => {
  const navigate = useNavigate();
  const [showPleaseWaitSection, setShowPleaseWaitSection] = useState(true);
  const [loader, setLoader] = useState(false);
  const [assismentDetails, setAssismentDetails] = useState({});
  const checkTokenExpiration = (token) => {
    try {
      const decoded = jwtDecode(token);
      if (decoded.exp * 1000 < Date.now()) {
        return true;
      }
    } catch (e) {
      console.error("Invalid token");
    }
    return false;
  };

  const checkAccessToken = () => {
    const currentAccessToken = new Cookies().getCookieValue(ACCESS_TOKEN);
    if (!currentAccessToken || checkTokenExpiration(currentAccessToken)) {
      new Cookies().remove(ACCESS_TOKEN);
      Utils.localStorageClear();
      Utils.failureToastMessage(
        failureMessageConstant.SESSION_EXPIRED_PLEASE_LOGIN_AGAIN
      );
      navigate("/?type=login", { replace: true });
      return;
    }
  };

  useEffect(() => {
    const interval = setInterval(checkAccessToken, 2000);
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    const userDetailData = async () => {
      await assessmentDatail();
    };
    userDetailData();
  }, []);
  const convertEpochToDate = (epochTimeInSeconds) => {
    // Convert seconds to milliseconds
    const epochTimeInMilliseconds = epochTimeInSeconds * 1000;

    // Create a new Date object
    const date = new Date(epochTimeInMilliseconds);

    // Format the date (e.g., MM/DD/YYYY)
    const formattedDate = date.toLocaleDateString("en-US");

    return formattedDate;
  };
  const assessmentDatail = async () => {
    const assessmentID = JSON.parse(localStorage.getItem(ASSISMENT_DETAIL));
    try {
      setLoader(true);
      const response = await new ClaimManagerService().assessmentDetailById(
        assessmentID?._id
      );
      if (!response) {
        setLoader(false);
        Utils.failureToastMessage(
          failureMessageConstant.ERROR_WHILE_CALLING_API_DOCUMENT
        );
      } else {
        console.log("response", response);
        setAssismentDetails(response);
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
      Utils.failureToastMessage(
        failureMessageConstant.ERROR_WHILE_CALLING_API_DOCUMENT
      );
    }
  };
  const ASSESSMENT_DETAILS = [
    {
      name: "Assessment ID",
      assessmentId: 0,
      value: assismentDetails?._id || "-"
    },
    {
      name: "Name of Injured",
      assessmentId: 1,
      value:
        assismentDetails?.firstName + " " + assismentDetails?.lastName || "-"
    },
    {
      name: "Date of Birth",
      assessmentId: 2,
      value: assismentDetails?.dateOfBirth ? assismentDetails?.dateOfBirth : "-"
    },
    {
      name: "Date of Injury",
      assessmentId: 3,
      value: assismentDetails?.dateOfInjury
        ? assismentDetails?.dateOfInjury
        : "-"
    },
    {
      name: "Place of Injury",
      assessmentId: 4,
      value: assismentDetails?.placeOfInjury || "-"
    },
    {
      name: "ZIP Code",
      assessmentId: 5,
      value: assismentDetails?.zipCode || "-"
    }
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPleaseWaitSection(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="w-full flex justify-center items-start bg-grey-f7f7f7">
      <div className="w-full max-w-152 h-[700px] rounded-4.5 bg-white pl-7 pr-[31px] pt-8 shadow-modal mt-24">
        {showPleaseWaitSection ? (
          <div className=" flex h-[480px] flex-col items-center justify-center">
            <img src="/images/search-grey.gif" className="size-34.5" />
            <p className="mt-9 font-Inter text-ft15-17 font-normal text-black-15">
              Please wait...
            </p>
          </div>
        ) : (
          <div>
            <div className="flex justify-between">
              <div className="flex w-full gap-1.5 font-Inter text-ft24-32 font-semibold mb-2.5">
                Assessment Report
              </div>
            </div>
            <div className="h-auto rounded-2.5 bg-blue-F1F7FF px-5 py-[13px]">
              {ASSESSMENT_DETAILS.map((item, index) => (
                <div key={index}>
                  <div className="mb-2 flex items-center justify-between">
                    <p className="font-Inter text-ft14-20 font-normal text-grey-25">
                      {item?.name}
                    </p>
                    <div className="flex gap-1">
                      {/* <img
                        className={`${index === 3 || index === 4 ? "block" : "hidden"}`}
                        src="/images/verified.svg"
                      /> */}
                      <p className="font-Inter text-ft15-17 font-medium text-black-15">
                        {item?.name === "Date of Injury" ||
                        item?.name === "Date of Birth"
                          ? convertEpochToDate(item?.value)
                          : item?.value}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="mt-4">
              <div className=" mb-2 flex items-center justify-between">
                <p className=" font-Inter text-ft14-20 font-bold text-black-15">
                  Injury
                </p>
                <p className=" font-Inter text-ft14-20 font-bold text-black-15">
                  Compensation
                </p>
              </div>
              <hr className=" text-grey-DFDFDF" />
              {INJURY_TYPES.map((item, index) => (
                <div key={index}>
                  <div className="mt-2.5 flex items-center justify-between">
                    <p className="font-Inter text-ft15-17 font-normal text-black-15">
                      {item?.name}
                    </p>
                    <p className="font-Inter text-ft15-17 font-medium text-black-15">
                      {item?.amount}
                    </p>
                  </div>
                  <p className="mb-2.5 mt-1.5 font-Inter text-ft14-20 font-normal text-grey-35">
                    {item?.conclusion}
                  </p>
                  <hr className=" border-t border-dashed border-grey-A2A2A2" />
                </div>
              ))}
              <div className="mt-2.5 flex items-center justify-between">
                <p className="font-Inter text-ft15-17 font-normal text-black-15">
                  Total Compensation
                </p>
                <p className="font-Inter text-ft15-17 font-medium text-black-15">
                  $17,065.00
                </p>
              </div>
              <div className="mt-2 flex items-center justify-between">
                <p className="mb-2.5 font-Inter text-ft15-17 font-normal text-red-FB5A5A">
                  Late medical treatment (-10%)
                </p>
                <p className="mb-2.5 mt-1.5 font-Inter text-ft15-17 font-medium text-red-FB5A5A">
                  $1706.55
                </p>
              </div>
              <hr className="mb-3 mt-2 text-grey-DFDFDF" />
              <div className="flex items-center justify-between">
                <p className="font-Inter text-ft18-21 font-semibold text-black-15">
                  Total Case Value
                </p>
                <p className="font-Inter text-ft18-21 font-semibold text-black-15">
                  $15,358.45
                </p>
              </div>
            </div>
            <button className="mb-2.5 mt-11 h-53px w-full rounded-lg bg-blue-146EF6 font-Inter text-ft18-21 font-medium text-white">
              Submit Report
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AssessmentReport;
