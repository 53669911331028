/* eslint-disable tailwindcss/enforces-shorthand */
/* eslint-disable tailwindcss/classnames-order */
/* eslint-disable tailwindcss/no-custom-classname */
import React from "react";
import Popup from "reactjs-popup";
import { termAndCondition } from "../../constants";

function TermAndConditionPopup({ isPopupOpen, closePopup }) {
  return (
    <Popup
      open={isPopupOpen}
      closeOnDocumentClick={false}
      onClose={closePopup}
      overlayStyle={{
        backgroundColor: "rgba(247, 247, 247, 0.4)",
        zIndex: 1000
      }}
    >
      <div className=" font-Inter font-semibold text-black-15 text-ft24-29">
        Terms and Conditions
      </div>
      <div
        className="w-full border border-grey-D0D0D0 rounded-tl-xl  rounded-bl-xl p-5 mt-6 scroll-min-height font-Inter font-normal text-ft15-24 text-black-15"
        dangerouslySetInnerHTML={{ __html: termAndCondition }}
      />
      <button
        className="mt-9 h-53px w-full rounded-lg bg-blue-146EF6 text-ft18-21 font-normal text-white outline-none"
        onClick={closePopup}
      >
        Agree and Continue
      </button>
    </Popup>
  );
}

export default TermAndConditionPopup;
