/* eslint-disable quotes */
/**
 * Created by Ayush Kulshrestha on 18/09/2019.
 */

export const httpConstants = {
  METHOD_TYPE: { POST: "POST", PUT: "PUT", GET: "GET", DELETE: "DELETE" },
  CONTENT_TYPE: {
    APPLICATION_JSON: "application/json",
    MULTIPART_FORM_DATA: "multipart/form-data",
    APPLICATION_FORM_URLENCODED: "application/x-www-form-urlencoded",
    IMAGE_PNG: "image/png"
  },
  DEVICE_TYPE: { WEB: "web" },
  API_END_POINT: {}
};

export const formConstants = {
  ACTION_TYPES: {
    UDPATE_STATE: "UDPATE_STATE"
  }
};

export const keyConstants = {
  USER: "user",
  ACCESS_TOKEN: "ACCESS_TOKEN",
  ID: "id",
  AUTH_ID: "authId",
  EMAIL_ID: "emailId",
  IS_GOOGLE_LOGIN: "isGoogleLogin",
  ASSISMENT_DETAIL: "assessmentDetail",
  INJURY_TYPE: "injuryType",
  SURVEY_QUESTIONS: "surveyQuestions"
};
export const apiEndPointConstants = {
  CHECK_USER_EXISTS: "/public/check-user-exists",
  OAUTH_TOKEN: "/oauth/token",
  USER: "/user",
  CHANGE_PASSWORD: "/change-password",
  PERSONAL_DETAIL: "/personal-details",
  SURVEY_QUESTION: "/survey-questions",
  UPLOAD_REPORTS: "/upload-reports",
  RESEND_VERIFY: "/public/resend-verify",
  INJURY_SURVEY_QUESTION: "/injury-survey-question",
  FORGET_PASSWORD: "/public/forget-password",
  USER_LAST_LOGIN: "/user/last-login",
  INJURY_COMPENSATION: "/injury-compensation",
  USER_DETAIL: "/user-details",
  ASSESSMENT_DETAIL: "/assessment-details"
};

export const hideSidebarPaths = [
  "/",
  "/report",
  "/forget-password",
  "/user-otp",
  "/history"
];

export const sidebarItems = [
  {
    name: "Personal Details",
    path: "/personal-details",
    icon: "/images/person.svg",
    activeIcon: "/images/person.svg"
  },
  {
    name: "Injury Survey",
    path: "/injury-survey",
    icon: "/images/survey.svg",
    activeIcon: "/images/survey-active.svg"
  },
  {
    name: "Upload Documents",
    path: "/injury-survey/3",
    icon: "/images/document.svg",
    activeIcon: "/images/upload-active.svg"
  },
  {
    name: "Assessment Report",
    path: "/injury-survey/4",
    icon: "/images/report.svg",
    activeIcon: "/images/assessment-icon.svg"
  }
];

export const SURVEY_QUESTIONS = [
  {
    question:
      "Was the cost of repairs for the property damage sustained to the vehicle you were operating more than $2,000?",
    options: [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" }
    ]
  },
  {
    question:
      "If you were employed on the date of the accident, how many consecutive days of work did you miss as a result of the injuries suffered in the accident?",
    options: [
      { label: "1 week", value: "1 week" },
      { label: "Less than two weeks", value: "Less than two weeks" }
    ]
  },
  {
    question:
      "How long after the accident did you first seek medical treatment?",
    options: [
      { label: "Between 4-14 days", value: "Between 4-14 days" },
      { label: "Between 5-15 days", value: "Between 5-15 days" }
    ]
  },
  {
    question:
      "Did you ever have any medical treatment for any of the same body parts that were injured in the subject accident?",
    options: [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" }
    ]
  }
];

export const UPLOAD_DOCUMENTS = [
  {
    documentName: "MRI Report",
    documentId: 0
  },
  {
    documentName: "X-Ray Report",
    documentId: 1
  },
  {
    documentName: "CT Scan Report",
    documentId: 2
  },
  {
    documentName: "Surgery Report",
    documentId: 3
  },
  {
    documentName: "FIR Report (Optional)",
    documentId: 4
  },
  {
    documentName: "Property Damage  Report (Optional)",
    documentId: 5
  }
];
export const ASSESSMENT_DETAILS = [
  {
    name: "Assessment ID",
    assessmentId: 0,
    value: "SSA0584"
  },
  {
    name: "Name of Injured",
    assessmentId: 1,
    value: "John Appleseed"
  },
  {
    name: "Date of Birth",
    assessmentId: 2,
    value: "11/09/1994"
  },
  {
    name: "Date of Injury",
    assessmentId: 3,
    value: "04/12/2024"
  },
  {
    name: "Place of Injury",
    assessmentId: 4,
    value: "Middletown, NY"
  },
  {
    name: "ZIP Code",
    assessmentId: 5,
    value: "109400"
  }
];
export const INJURY_TYPES = [
  {
    name: "Cervical injury",
    injuryId: 0,
    conclusion: "Impingement suffered at C1-C2 level of the spine",
    amount: "$10,477.00"
  },
  {
    name: "Head Injury",
    injuryId: 1,
    conclusion: "Concussion Injury",
    amount: "$6,588.00"
  }
];
export const failureMessageConstant = {
  NAME_REQUIRED: "Full name is required",
  DONOT_HAVE_PERMISSION_TO_SIGNIN: "Please contact the admin to sign In",
  MAX_CHARS: "Name must not exceed 30 characters",
  EMAIL_REQUIRED: "Email is required",
  INVALID_EMAIL: "Please enter valid email",
  PASSWORD_REQUIRED: "Password is required",
  COFIRM_PASSWORD_REQUIRED: "Confirm password is required",
  INVALID_PASSWORD:
    "Passwords should be at least 8 characters, containing uppercase and lowercase letters, numbers, and symbols",
  INVALID_COFIRM_PASSWORD:
    "Confirm passwords should be at least 8 characters, containing uppercase and lowercase letters, numbers, and symbols",
  PASSWORD_MATCH_ERROR: "Confirm password do not match. Please re-enter",
  USER_ALREADY_EXISTS_PLEASE_SIGN_IN: "User already exists. Try signing in",
  ERROR_WHILE_UPDATING_USER: "Error occurred while updating user details",
  ERROR_WHILE_CHANGING_PASSWORD: "Error occurred while changing the password",
  DONOT_HAVE_PERMISSION_TO_SIGNUP:
    "Please contact the admin to sign up, as you do not have the necessary permissions",
  INCORRECT_EMAIL_PASSWORD: "Incorrect email or password",
  ACCOUNT_BLOCKED_CHECK_MAIL_FOR_UNBLOCK_INSTRUCTIONS:
    "Your account is blocked due to multiple login attempts. Check your email for unblocking instructions",
  PLEASE_SIGN_IN: "Email address already exists. Try signing in",
  PASSWORD_MISMATCH: "Confirm password do not match. Please re-enter",
  CURRENT_PASSWORD_FIELD_EMPTY: "Current password field is empty",
  NEW_PASSWORD_FIELD_EMPTY: "New password field is empty",
  CURRENT_PASSWORD_NOT_MAICHING: "Incorrect current password",
  ERROR_WHILE_SUBMITING_PERSONAL_DETAIL:
    "Error while submiting personal detail",
  SUBMITTED_PERSONAL_DETAIL_SUCCESSFULLY:
    "Submitted personal details successfully",
  FILL_ALL_PERSONAL_DETAIL: "All personal details are required",
  ERROR_WHILE_FETCHING_INJURY_SURVEY_QUESTION: "Error while fetching question",
  INJURY_QUESTION_FETCHED_SUCCESSFULLY: "Injury question fetched successfully",
  ERROR_WHILE_UPLOADING_DOCUMENT: "Error while uploading document",
  UPLOADING_DOCUMENT_SUCCESSFULLY: "Uploading document successfully",
  UPLOAD_MINIUM_ONE_DOCUMENT: "Please upload minimum one document",
  ONLY_PDF_FILE_ARE_ALLOWED: "Only PDF files are allowed",
  UNABLE_TO_RESENT_VERIFY_EMAIL: "Email is already verified, Please log in",
  UNABLE_TO_SENT_FORGET_PASSWORD_EMAIL: "Unable to sent forget password email",
  PLEASE_ANSWER_ALL_QUESTION: "Please answer all the survey questions",
  ERROR_WHILE_SAVING_INJURY_SURVEY_QUESTION: "Error while saving question",
  USER_IS_ALREDY_EXIST_PLEASE_USER_EMAIL_LOGIN:
    "User is alredy exist. Please use email login",
  USER_IS_ALREDY_EXIST_PLEASE_USER_GOOGLE_LOGIN:
    "User is alredy exist. Please use google login",
  ERROR_WHILE_UPDATING_PERSONAL_DETAIL: "Error while updating personal detail",
  SESSION_EXPIRED_PLEASE_LOGIN_AGAIN: "Session expired. Please login again",
  USER_DOES_NOT_EXIST_CONTACT_ADMIN:
    "User does not exist. Please contact admin",
  NEW_PASSWORD_MUST_DIFFERENT:
    "New password must differ from your current password",
  NOT_ABLE_TO_UPDATE_LAST_LOGIN: "Not able to update last login time",
  SOCIAL_SECURITY_NUMBER_REQUIRED_VALID:
    "Social security number must be 9 digits long",
  ERROR_WHILE_CALLING_API_DOCUMENT: "Error while calling API"
};
export const successMessageConstant = {
  SIGNUP_SUCCESS: "Sign up Successfully",
  SIGIN_SUCCESS: "Sign in Successfully",
  LOGOUT_SUCCESSFULLY: "Logout successfully",
  CHANGE_PASSWORD_SUCCESSFULLY: "Password changed successfully",
  VERIFY_EMAIL_RESENT_SUCCESSFULLY: "Verification email resent successfully",
  FORGET_PASSWORD_MAIL_SENT_SUCCESSFULLY: "Password reset email sent",
  INJURY_SURVEY_ANSWER_SAVED_SUCCESSFULLY:
    "Injury survey answer saved successfully",
  UPDATED_PERSONAL_DETAIL_SUCCESSFULLY: "Updated personal details successfully"
};
export const regexConstant = {
  EMAIL_REGEX: new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  ),
  PASSWORD_REGEX: new RegExp(
    /^(?=.*[A-Za-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\\]{8,}$/
  )
};
export const errorCode = {
  USER_NOT_VERIFIED: "USER_NOT_VERIFIED"
};
export const termAndCondition = `Welcome to CASESOLVE, INC. (“CASESOLVE”). You want your claim resolved. We share your vision for fair and efficient resolution to your claim without costly or time-consuming litigation. The first step to fairly and efficiently resolve your claim is to read and agree to this Agreement. Congratulations on taking the first step to resolving your claim.
<br/><br/>
By using CASESOLVE, you agree to follow and be bound by these terms of use and agree to comply with all applicable laws and regulations.  You further agree that you have attained the age of at least 18 years and legally able to enter into this Agreement.
<br/><br/>
1. CASESOLVE pledge: All personal injury claims may be submitted to CASESOLVE for adjudication. Claims will be resolved by CASESOLVE in their entirety by issuing a monetary award that includes compensation for all claims of negligence, contributory negligence, economic damages, non-economic damages, outstanding medical bills, causation, and permanency. Not every case will qualify for a CASESOLVE review and/or award. CASESOLVE reserves the right to accept or decline any claim for its services based on the nature of the claim without explanation or reasoning.  
<br/><br/>
2. Official Police or Accident Report: Claimant will submit any and all Official Police reports and/or Accident Reports to CASESOLVE for review of the claim.
<br/><br/>
3.Record retrieval: CASESOLVE will obtain and analyze pertinent medical records for the sole purpose of assessing the extent of the injuries sustained and the legal value of your personal injury claim. Claimant consents and agrees to waive any and all rights of privacy to such medical records for this purpose, including, but not limited to, any rights of privacy granted by the Health Insurance Portability and Accountability Act and/or the physician/patient privilege. Claimant also agrees to cooperate in the release of any and all pertinent medical records and CASESOLVE’s procurement of said records. Therefore, the claimant must (i) list each and every medical provider consulted or visited for any body part that was potentially injured in the subject accident; (ii) list each and every medical provider consulted or visited before the subject accident for any body part that was potentially injured in the subject accident; (iii) electronically sign a Health Insurance Portability and Accountability Act (HIPAA) release allowing CASESOLVE to obtain the medical records from each such provider; (iv) claimant agrees that CASESOLVE may obtain such records for the sole purpose of assessing the value of claimant’s personal injury claim, and waives any and all patient/physician privilege for all such medical records.
<br/><br/>
4. No Fee Guarantee: Pursuant to this agreement, it is impossible for claimant to owe any money to CASESOLVE unless money is awarded to claimant. If CASESOLVE does not award any money to claimant, then claimant pays nothing and CASESOLVE will receive no fee or compensation whatsoever. If CASESOLVE does award money, then in consideration for services rendered, claimant agrees that CASESOLVE will receive twenty-five percent (25%) of the total amount awarded as compensation for all services rendered. CASESOLVE may charge an additional 3% processing fee to be paid exclusively and solely by the subject insurance company, and not to be paid by claimant.
<br/><br/>
5. Disbursements: To fairly and equitably evaluate the claim, CASESOLVE is required to expend funds to obtain the claimant’s necessary medical records. Medical providers will provide a cost for the production and copying of the claimant’s medical records. CASESOLVE will pay all such reasonable costs on behalf of claimant. Only in the event that claimant receives a monetary award, claimant agrees to reimburse CASESOLVE for the costs of obtaining such records. Such costs will not exceed a total of $100 for obtaining the records in addition to the actual amount paid to each medical provider.  In the event there is no monetary award to Claimant, then pursuant to the CASESOLVE no-fee guarantee, the claimant owes nothing.
<br/><br/>
6. Final Decision: CASESOLVE serves as the final arbitrator for all issues regarding your personal injury claim. CASESOLVE’s decision and award are final and binding with prejudice upon all parties. CASESOLVE reserves the exclusive right to review and modify any final award before issuance of funds. The payment of the CASESOLVE monetary award is not to be construed as an admission of liability by any party as CASESOLVE will issue a final determination of disputed claims. The maximum amount of the CASESOLVE monetary award will not exceed the confirmed insurance policy limit of the insured. No CASESOLVE award shall act as a judgment to be levied against the insured in any capacity. All parties agree to waive any and all judicial appeals in favor of the exclusive CASESOLVE appeal process. To appeal a CASESOLVE decision or award, all CASESOLVE appeals must adhere to each and every regulation of the exclusive CASESOLVE appeal process. Submissions that fail to follow CASESOLVE regulations will not be considered. The exclusive CASESOLVE appeal process can result in an increase or decrease of any monetary award. The CASESOLVE exclusive appeal process is as follows: (i) CASESOLVE will accept appeals only in writing and submitted only by email within 48 hours to appealdepartment@casesolve.com; (ii) only appeals will be considered and emails in any other form will not be considered; (iii) all appeals must have the appellant’s name, email, and CASESOLVE docket number as the first words displayed at the top of the submission; (iv) a CASESOLVE appeal has a maximum limit of 200 typed words. Content beyond 200 words will not be reviewed or considered; (v) CASESOLVE reserves the right to issue a decision regarding any submitted CASESOLVE appeal at any time and without time limits;  (vi) CASESOLVE appeal decisions will be issued and include only a notification as to whether the subject decision or award is upheld, increased, or decreased.  
<br/><br/>
7. Affirmation of Truth: Claimant attests and/swears that the representations made to CASESOLVE are complete, true, and accurate. Intentional misrepresentations can constitute fraud and be subject to criminal prosecution.
<br/><br/>
8. Release: In consideration for the CASESOLVE determination of my claim and/or any amount of money awarded, Claimant hereby releases and forever discharges any and all potential defendants/insurance companies/related entities and their employees, agents, representatives, principals, attorneys, subsidiaries, affiliates, assigns, predecessors, and successors in interest from all claims of any kind or character, which claimant may have or might have against them, and especially because of all damages, losses or injuries to person, whether developed or underdeveloped or known or unknown, resulting or to result from the incident that occurred as reflected in the CASESOLVE file and award. Claimant further acknowledges full settlement and satisfaction of all claims, demands, actions and causes of action which claimant may have against them for any losses or injuries. Claimant further acknowledges that CASESOLVE will render a final determination of a disputed legal claim. Claimant releases CASESOLVE, and its related entities and their employees, agents, representatives, principals, attorneys, subsidiaries, affiliates, assigns, predecessors, and successors in interest from all claims ever accrued or to be accrued.
<br/><br/>
9. Liens: Claimant covenants to pay and satisfy any asserted lien or reimbursement rights, including Workers’ Compensation liens, liens related to the repair of the vehicle owned or operated by the Claimant(s), medical liens, disability benefit liens, Social Security liens, Medicare liens, Medicaid liens and all other liens. Claimant  agrees to hold any and all parties harmless from any and all liens that may exist on any monetary award, and agrees that claimant, and claimant alone, is solely responsible for the monetary satisfaction of all liens.
<br/><br/>
10. Right to Counsel: Claimant understands and acknowledges that this Agreement, and any final decision by CASESOLVE, is binding, and thus that claimant is waiving the right to a trial and appeal. Claimant also acknowledges that claimant is aware of the right to consult with an attorney before entering the CASESOLVE Agreement, and acknowledges that any and all fees due to any attorney engaged by the Claimant must be paid solely by the Claimant directly to said attorney according to the terms of any validly executed retainer agreement.  At no time does CASESOLVE review your answers for legal sufficiency or provide legal advice, opinions or recommendations about your legal rights, remedies, defenses, or options. Your use of CASESOLVE does not and will not create an attorney-client relationship between you and CASESOLVE. CASESOLVE is not a law firm and will not perform services performed by an attorney on your behalf, but will act as the final judge and/or arbitrator of your claim.
<br/><br/>
11. Authorized use: When you open a CASESOLVE account, or use its services, you must provide complete and accurate information as requested under penalty of perjury. You will also be asked to provide a username and password. You are entirely responsible for maintaining the confidentiality of your username and password. You may not use a third party's account, username or password at any time. You agree to notify CASESOLVE immediately of any unauthorized use of your account, username or password. CASESOLVE shall not be liable for any losses incurred as a result of someone else's use of your account or password, either with or without your knowledge.
<br/><br/>
12. Arbitration Agreement: You agree that all legal disputes, in any, involving CASESOLVE are to be resolved without traditional litigation in favor of the sole remedy of binding arbitration. Terms for such arbitration are set forth separately in the Arbitration Agreement, which are fully incorporated and adopted as part of this User Agreement. The Arbitration Agreement can be found here.
<br/><br/>
13. Insurance Policy Limits: CASESOLVE will determine the value of your case as presented within the applicable insurance policy limit of any insured that was involved in the subject incident or within the limits of the uninsured or supplemental uninsured motorist policy limit.  CASESOLVE will not render an award that exceeds the available police limit of any alleged tortfeasor. Therefore, by agreeing to have CASESOLVE determine the amount of your claim, you agree that the amount of your recovery will be within the applicable insurance policy limit of any potential tortfeasor. Where applicable, it is your responsibility to protect any rights you may have to any and all potential supplemental insurance benefits, including uninsured motorist benefits or supplemental uninsured motorist benefits.
<br/><br/>
14. Acknowledgement: Claimant affirms that claimant has (i) carefully read the entire Agreement, (ii) is of sound mind and as of the time of the entering of this Agreement is not under the influence of any drug, narcotic, or medication that would impair the claimant’s judgment, (iii) fully understands its contents, (iv) enters into this Agreement voluntarily and with full authority, (v) has not been pressured or coerced to enter into this Agreement, (vi) believes the terms of this Agreement to be fair, and (vii) will be bound by all of terms of this Agreement.
`;
export const historyDummyIds = [
  "SSA0584 - 30 Mar 2024",
  "SSA0584 - 30 Mar 2024",
  "SSA0581 - 31 Mar 2024"
];
export const faq = [
  {
    q: "Why CASESOLVE?",
    ans: "Using CASESOLVE can save you lots of time and money by resolving your claim quickly and without lengthy litigation, which often takes several years."
  },
  {
    q: "Do I pay CASESOLVE any money from my own pocket?",
    ans: "No, CASESOLVE will only collect a fee if your case is awarded money. If your case is awarded money, CASESOLVE’s fee will be much less than the cost of hiring an attorney at 25% of the award amount."
  },
  {
    q: "Are there any other fees I have to pay?",
    ans: "There are no other fees you have to pay to CASESOLVE, but you are responsible for paying any valid liens that could potentially exist on your award. Click here to learn more about liens."
  },
  {
    q: "How does CASESOLVE determine what my case is worth?",
    ans: "CASESOLVE analyzes your claim by using many different proven factors of personal injury law including how the incident occurred, the severity of the injuries suffered, a medical record analysis, prior legal cases, prior jury verdicts, past legal results, the physical limitations, emotional harm, lost wages, loss of enjoyment of life, and several other factors to provide the most accurate and fast result to your personal injury case."
  },
  {
    q: "If I win a CASESOLVE award, when will I receive my funds?",
    ans: "Typically, CASESOLVE funds will be provided within 48 hours. "
  },
  {
    q: "Will I be satisfied with my CASESOLVE award?",
    ans: "All cases have risk, but we believe that you will enjoy getting a quick and accurate result to your case so that you can have your money NOW, and have the peace of mind knowing that your case is solved."
  },
  {
    q: "Does CASESOLVE accept every case?",
    ans: "No, CASESOLVE accepts only certain personal injury cases that qualify for its services."
  },
  {
    q: "Will my case qualify for a CASESOLVE award?",
    ans: "To determine if your case qualifies for a CASESOLVE award, simply submit a request to CASESOLVE by clicking here, or call us at 1-800-CASESOLVE."
  },
  {
    q: "What if I am not satisfied with my CASESOLVE award?",
    ans: "All CASESOLVE results are final and binding on all parties. You can appeal your award only through the CASESOLVE appeal process. No other appeals are permitted, but the CASESOLVE appeal process is quick and easy. You can learn more about the CASESOLVE appeal process by clicking here."
  },
  {
    q: "Once I win an award with CASESOLVE, can I bring a case in Court?",
    ans: "No, the CASESOLVE award is final and binding for all parties"
  },
  {
    q: "Do I need an attorney?",
    ans: "You have a constitutional right to hire an attorney, but you do not need an attorney to have CASESOLVE issue you a final award."
  }
];
export const dropDownData = [
  {
    name: "Mississippi (Most Affordable)",
    value: "Mississippi (Most Affordable)"
  },
  {
    name: "Alabama",
    value: "Alabama"
  },
  {
    name: "Arkansas",
    value: "Arkansas"
  },
  {
    name: "Kentucky",
    value: "Kentucky"
  },
  {
    name: "West Virginia",
    value: "West Virginia"
  },
  {
    name: "Iowa",
    value: "Iowa"
  },
  {
    name: "Oklahoma",
    value: "Oklahoma"
  },
  {
    name: "South Dakota",
    value: "South Dakota"
  },
  {
    name: "Louisiana",
    value: "Louisiana"
  },
  {
    name: "New Mexico",
    value: "New Mexico"
  },
  {
    name: "Kansas",
    value: "Kansas"
  },
  {
    name: "North Dakota",
    value: "North Dakota"
  },
  {
    name: "Missouri",
    value: "Missouri"
  },
  {
    name: "Tennessee",
    value: "Tennessee"
  },
  {
    name: "Indiana",
    value: "Indiana"
  },
  {
    name: "Ohio",
    value: "Ohio"
  },
  {
    name: "Nebraska",
    value: "Nebraska"
  },
  {
    name: "Wyoming",
    value: "Wyoming"
  },
  {
    name: "Montana",
    value: "Montana"
  },
  {
    name: "Wisconsin",
    value: "Wisconsin"
  },
  {
    name: "South Carolina",
    value: "South Carolina"
  },
  {
    name: "Idaho",
    value: "Idaho"
  },
  {
    name: "North Carolina",
    value: "North Carolina"
  },
  {
    name: "Michigan",
    value: "Michigan"
  },
  {
    name: "Georgia",
    value: "Georgia"
  },
  {
    name: "Maine",
    value: "Maine"
  },
  {
    name: "Pennsylvania",
    value: "Pennsylvania"
  },
  {
    name: "Utah",
    value: "Utah"
  },
  {
    name: "Minnesota",
    value: "Minnesota"
  },
  {
    name: "Delaware",
    value: "Delaware"
  },
  {
    name: "Vermont",
    value: "Vermont"
  },
  {
    name: "Arizona",
    value: "Arizona"
  },
  {
    name: "Texas",
    value: "Texas"
  },
  {
    name: "Nevada",
    value: "Nevada"
  },
  {
    name: "Illinois",
    value: "Illinois"
  },
  {
    name: "New Hampshire",
    value: "New Hampshire"
  },
  {
    name: "Rhode Island",
    value: "Rhode Island"
  },
  {
    name: "Florida",
    value: "Florida"
  },
  {
    name: "Virginia",
    value: "Virginia"
  },
  {
    name: "Alaska",
    value: "Alaska"
  },
  {
    name: "Oregon",
    value: "Oregon"
  },
  {
    name: "Connecticut",
    value: "Connecticut"
  },
  {
    name: "Colorado",
    value: "Colorado"
  },
  {
    name: "Maryland",
    value: "Maryland"
  },
  {
    name: "Massachusetts",
    value: "Massachusetts"
  },
  {
    name: "Washington",
    value: "Washington"
  },
  {
    name: "New York",
    value: "New York"
  },
  {
    name: "New Jersey",
    value: "New Jersey"
  },
  {
    name: "Hawaii",
    value: "Hawaii"
  },
  {
    name: "California (Most Expensive)",
    value: "California (Most Expensive)"
  }
];
export const tooltipContent = [
  "Select the type of accident that caused your injury.",
  "Answer the questions below to provide more details about your injuries.",
  "i. Please Upload all relevant medical reports and an official incident report (e.g., FIR).",
  "ii. Note: Failure to provide an incident report  will result in claim rejection.",
  "Submit your personal information to continue with the claim process."
];
