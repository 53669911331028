/* eslint-disable quotes */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable tailwindcss/classnames-order */
/* eslint-disable react/no-unknown-property */
/* eslint-disable tailwindcss/no-unnecessary-arbitrary-value */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable tailwindcss/enforces-shorthand */
/* eslint-disable tailwindcss/no-custom-classname */
import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../../assets/images/logo-white.svg";
import { ReactComponent as LoginContent } from "../../assets/images/login-content.svg";
import VerificationInput from "../../common/components/varificationInput";
import { useNavigate } from "react-router";
import UserService from "../../services/userService";
import Utils from "../../utility";
import { failureMessageConstant, keyConstants } from "../../constants";
import { jwtDecode } from "jwt-decode";
import Cookies from "../../managers/sessionManager";
const { USER, ACCESS_TOKEN } = keyConstants;

const UserOtpComponent = () => {
  const [otp, setOtp] = useState("");
  const user = Utils.localStorageGetItem(USER);
  const navigate = useNavigate();

  const checkTokenExpiration = (token) => {
    try {
      const decoded = jwtDecode(token);
      if (decoded.exp * 1000 < Date.now()) {
        return true;
      }
    } catch (e) {
      console.error("Invalid token");
    }
    return false;
  };

  const checkAccessToken = () => {
    const currentAccessToken = new Cookies().getCookieValue(ACCESS_TOKEN);
    if (!currentAccessToken || checkTokenExpiration(currentAccessToken)) {
      new Cookies().remove(ACCESS_TOKEN);
      Utils.localStorageClear();
      Utils.failureToastMessage(
        failureMessageConstant.SESSION_EXPIRED_PLEASE_LOGIN_AGAIN
      );
      navigate("/?type=login", { replace: true });
      return;
    }
  };

  useEffect(() => {
    const interval = setInterval(checkAccessToken, 2000);
    return () => clearInterval(interval);
  }, []);
  const handleComplete = (code) => {
    console.log("Verification code entered:", code);
    setOtp(code);
    // You can trigger an API call or any other action here
  };
  const updateLastLogin = async () => {
    try {
      const [err, updateUserRes] = await Utils.parseResponse(
        new UserService().userLastLogin({
          id: user?._id
        })
      );
      if (err) {
        Utils.failureToastMessage(
          failureMessageConstant.NOT_ABLE_TO_UPDATE_LAST_LOGIN
        );
      } else {
        //
      }
    } catch (error) {
      Utils.failureToastMessage(
        failureMessageConstant.NOT_ABLE_TO_UPDATE_LAST_LOGIN
      );
    } finally {
      /* empty */
    }
  };
  const signInHandler = () => {
    console.log("signin", otp);
    updateLastLogin();
    navigate("/personal-details");
  };
  return (
    <>
      <div className="flex size-full">
        <div className=" flex min-h-[100vh] w-full flex-col items-center justify-between bg-white pt-60 pb-10 gap-10">
          <div className=" flex items-center flex-col">
            <Logo />
            <div className=" mt-10 h-369px w-105 rounded-lg shadow-modal">
              <div className=" font-Inter font-bold text-ft16-19.36 text-black-15 flex justify-center pt-6">
                Enter 6 digit code sent your Email ID.
              </div>
              <div className=" flex justify-center mt-10">
                <div className=" font-Inter font-normal text-ft14-20 text-black-15 w-[388px] text-center">
                  We have sent a 6 digit code to the email ID jo***********com.
                  Please enter the code to continue.
                </div>
              </div>
              <div className="mt-8 flex justify-between  gap-6 flex-col">
                <VerificationInput length={6} onComplete={handleComplete} />
              </div>
              <button
                className={`mt-10 h-51px w-96.5 rounded-lg bg-blue-146EF6 font-SF font-medium text-ft18-21 text-white ml-4 ${
                  otp.length !== 6 ? "opacity-50" : ""
                }`}
                onClick={signInHandler}
                disabled={otp.length !== 6}
              >
                Sign In
              </button>
              <div className="mb-3 flex w-full justify-center font-Inter text-ft14-20 font-normal mt-5">
                Didn’t get code?
                <span className="ml-1 text-blue-146EF6 cursor-pointer">
                  Resend
                </span>
              </div>
            </div>
          </div>

          <div className="h-9 w-41.5 text-center font-Inter text-ft15-17 font-medium tracking-tight text-black-15">
            2024 All Right Reserved Privacy and Terms
          </div>
        </div>
        <div className=" flex min-h-[100vh] w-full max-w-[680px] flex-col items-center bg-[#146EF6]">
          <span className="mt-[12%] w-full max-w-[75%] text-center text-ft30-36 font-bold text-white">
            Discover Your Injury Compensation Value with Ease!
          </span>
          <LoginContent className=" mt-[13%]" />
        </div>
      </div>
    </>
  );
};

export default UserOtpComponent;
