/* eslint-disable tailwindcss/classnames-order */
/* eslint-disable no-console */
import React, { useState, useEffect, useRef } from "react";
import { IoIosArrowDown } from "react-icons/io";

const SurveyOptions = ({
  value,
  data = [],
  question,
  onChange,
  questionId,
  placeHolder = "Select",
  customizeDropDown = {
    maxWidth: "md:max-w-38.5"
  }
}) => {
  const [showOption, setShowOption] = useState(false);
  const dropdownRef = useRef(null);

  const handleChange = (item) => {
    onChange(question, item, questionId); // Pass the selected value to the handler in the main component
    setShowOption(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowOption(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={dropdownRef}
      className={`relative size-full cursor-pointer rounded ${customizeDropDown.maxWidth} `}
    >
      <div
        onClick={() => setShowOption((prev) => !prev)}
        className="mt-0.5 flex min-h-8 w-full items-center justify-between gap-1 rounded-lg bg-blue-EDF4FF px-2 outline-none"
      >
        <div className="">
          <p
            className={`overflow-hidden whitespace-normal break-words font-Inter py-1 text-ft14-20 font-normal ${!value?.answer?.value.length ? "text-grey-BDBDBD" : "text-black-15"}`}
          >
            {value?.answer?.value
              ? value?.answer?.value?.length > 12
                ? value?.answer?.value
                : value?.answer?.value
              : placeHolder}
          </p>
        </div>
        <IoIosArrowDown
          className={`${showOption && "-rotate-180"} size-4 duration-100 min-w-4`}
        />
      </div>
      {showOption && (
        <ul className="absolute z-10 mt-1 flex w-full flex-col rounded-lg bg-white border border-grey-E5E5E5">
          {data.map((item, index) => (
            <li
              className="flex flex-col overflow-hidden whitespace-normal break-words rounded bg-center p-2 font-Inter text-ft14-20 font-normal"
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "#EDF4FF"; // Tailwind's blue-500 color
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "";
              }}
              key={index}
              onClick={() => handleChange(item)}
            >
              {item.value}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SurveyOptions;
